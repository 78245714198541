import '../styles/globals.css'

import { useEffect } from "react";
import router from "next/router";
// import { pageview } from "../lib/ga";


import type { AppProps } from 'next/app'



function MyApp({ Component, pageProps }: AppProps) {
  
  useEffect(() => {
    // const handleRouteChange = () => {
    //   pageview(router.route);
    // };

    //When the component is mounted, subscribe to router changes
    //and log those page views
    // router.events.on("routeChangeComplete", handleRouteChange);

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method

    import('react-facebook-pixel')
      .then((x) => x.default)
      .then((ReactPixel) => {
        ReactPixel.init('1887200428133276')
        ReactPixel.pageView()

        router.events.on('routeChangeComplete', () => {
          ReactPixel.pageView()
        })
      })


    // return () => {
    //   router.events.off("routeChangeComplete", handleRouteChange);
    // };
  }, []);


  return <Component {...pageProps} />
}

// export default MyApp

export default MyApp
